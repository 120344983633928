import React, { useEffect } from "react"
import { navigate } from "@reach/router"
import SEO from "@components/SEO"
import "@styles/index.scss"
import { Link } from "gatsby"

const RisksPage = () => {
  useEffect(() => {
    navigate("/risks/overview")
  }, [])
  // comment
  return <div></div>
}

export default RisksPage
